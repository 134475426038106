<template>
  <div class="auth-body ribbon-container">
    <div class="auth-el">

       <auth-card>
            <sign-in-form></sign-in-form>
            <div style="margin-top: 60px;">
              <span class="footer-text">
                New to bKash Business Dashboard? <a @click="$router.push('/sign-up')" style="color: #ffcb15;">Join Now</a>
              </span>
            </div>
       </auth-card>
    </div>
  </div>
</template>

<script>
import SignInForm from "@/components/auth/components/SignInForm";
import AuthCard from "@/components/auth/components/AuthCard";

export default {
  name: "SignIn",
  components: {
    signInForm: SignInForm,
    AuthCard,
  },
}
</script>

<style scoped>
/* .auth-head-layout {
  display: flex;
  flex-direction: column
} */

.header-text-bold {
  font-family: Roboto;
  font-size: 45px;
  margin-top: 50px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  line-height: 0.48;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
}

.header-text {
  font-family: Roboto;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.48;
  letter-spacing: normal;
  text-align: center;
  color: #95989a;
}

.footer-text {
  font-family: Roboto;
  font-size: 22px;
  margin-top: 25px;
  font-weight: bold;
  color: #95989a;
  text-align: center;
}

@media only screen and (max-width: 425px) {
  .footer-text {
    font-size: 15px;
  }
}
</style>
