<template>
  <div class="responsive">
    <v-form class="form-style" v-model="isValid">
      <v-text-field
          v-model="user.phoneNumber"
          class="inputNumber input-field-size"
          :rules="numberRules"
          label="Mobile Number"
          placeholder="Mobile Number"
          type="text"
          required
          outlined
          @input="validateInput"
      >
      </v-text-field>
      <v-text-field
          v-model="user.password"
          class="input-field-size"
          :rules="passwordRules"
          type="password"
          label="Password"
          placeholder="Password"
          @keydown.enter="handleLogin"
          required
          outlined
      >
      </v-text-field>
      <div class="recaptcha-v2-container" ref="recaptchaV2Container"></div>
      <v-btn
          color="primary"
          :disabled="!isValid"
          class="button-size"
          required
          style="font-size: 22px;margin-top: 5px"
          @click="handleLogin"
      >
        Sign in
      </v-btn>
    </v-form>
    <div>
      <span @click="forgotPassword" class="password-text">Forgot Password?</span>
    </div>
  </div>
</template>

<script>

import {DIGITS_ONLY_INPUT_RULE, PHONE_NUMBER_RULE} from "cpp-js-util/CppUtil";
import encryptionLib from "cpp-js-util/encryption";
import {recaptchaMixin} from "@/components/captcha/recaptcha-mixin";

export default {
  name: "SignInForm",
  mixins: [recaptchaMixin],
  data() {
    return {
      user: {
        phoneNumber: '',
        password: '',
      },
      isValid: true,
      numberRules: [
        v => !!v || 'Number is required',
        v => (v && v.length === 11) || 'Number must have 11 digit',
        v => PHONE_NUMBER_RULE.test(v) || 'Mobile number must be a valid number starting with 01',
      ],
      passwordRules: [
        v => !!v || 'Password is required'
      ],

    }
  },
  methods: {
    handleLogin: async function () {
      if (!this.isValid) return;

      await this.loadAndPrepareRecaptchaDetails();

      let payload = JSON.parse(JSON.stringify(this.user));
      let {nonce, publicKey} = await encryptionLib.getNonceAndRsaPublicKey(this.$cppClient, this.$feedback);
      payload.password = encryptionLib.encryptWithRsaPublicKeyAndNonce(publicKey, nonce, payload.password);

      this.$feedback.showLoading();

      payload.recaptchaDetails = this.recaptcha;

      try {
        let response = await this.$cppClient.post('/login', payload);
        let {data} = response;
        this.$feedback.hideLoading();
        this.$store.commit('setCredentials', data);
        this.$gtag.event("Logged in", {
          'event_category': "Sign In Page"
        });
        await this.$router.push({name: 'dashboard'});
      } catch (ex) {
        this.$feedback.hideLoading();
        let {response} = ex;
        let {data} = response;
        if (data.internalCode === 'CHANGE_TEMPORARY_PASSWORD') {
          this.$store.commit('setUserPhoneNumber', this.user.phoneNumber);
          await this.$router.push({name: 'confirm-sign-up'});
        } else if (data.internalCode === 'RECAPTCHA_V3_LOW_SCORE' || data.internalCode === 'RECAPTCHA_IS_REQUIRED') {
          await this.$feedback.showFailed(response);
          await this.startCaptchaV2Flow();
        } else {
          await this.$feedback.showFailed(response);
        }
      }
    },
    forgotPassword() {
      this.$router.push({name: 'forgot-password'});
    },
    validateInput() {
      const maxLength = 11;

      this.$nextTick(() => {
        this.user.phoneNumber = this.user.phoneNumber.replace(DIGITS_ONLY_INPUT_RULE, '')
      })

      if (this.user.phoneNumber.length > maxLength) {
        this.$nextTick(() => {
          this.user.phoneNumber = this.user.phoneNumber.slice(0, maxLength);
        })
      }
    }
  }
}
</script>

<style scoped>
.password-text {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 600;
  margin-top: 25px;
  font-stretch: normal;
  cursor: pointer;
  line-height: 12px;
  letter-spacing: normal;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  color: #707070;
}

.responsive {
  width: 100%;
}

.input-field-size {
  margin-bottom: 42px;
  width: 329px;
  height: 54px;
  display: inline-block;
}

.button-size {
  height: 39px;
  width: 329px;
}

.form-style {
  margin-bottom: 25px;
}

.input-field-size >>> .v-messages__message {
  hyphens: none !important;
}

@media only screen and (max-width: 425px) {
  .responsive {
    width: 100%;
  }

  .password-text {
    width: 100%;
    font-size: 15px;
  }

  .input-field-size {
    width: 100%
  }

  .button-size {
    width: 100%;
  }

  .form-style {
    display: inline-block;
    width: 261px;
  }

  .input-field-size >>> .v-messages__message {
    font-size: 10px !important;
  }

  .recaptcha-v2-container {
    transform: scale(0.8);
    transform-origin: 0 0;
  }
}

@media screen and (max-width: 768px) {
  .input-field-size {
    width: 329px !important;
  }
}

.recaptcha-v2-container {
  width: 329px !important;
  margin: auto;
  margin-top: -12px;
  margin-bottom: 12px;
}

</style>
